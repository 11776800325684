<template>
  <b-table
    class="table-head-custom"
    primary-key="id"
    stacked="xl"
    show-empty
    small
    fixed
    no-sort-reset
    no-local-sorting
    v-bind:busy="loading"
    v-bind:fields="fields"
    v-bind:items="cars"
    v-bind:sort-by.sync="sortBy"
    v-bind:sort-desc.sync="sortDesc"
    v-on:sort-changed="sortChanged"
  >
    <template v-slot:head()="row">
      <template v-if="sortBy === row.column">
        {{ row.label }}

        <i v-if="sortDesc" class="fas fa-sort-amount-down" />
        <i v-else class="fas fa-sort-amount-up" />
      </template>

      <template v-else>
        {{ row.label }}
      </template>
    </template>

    <template v-slot:cell(id)="row">
      <a
        href="#"
        class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg"
      >
        {{ row.item.id }}
      </a>
    </template>

    <template v-slot:cell(vehicle)="row">
      {{ row.value.registration }}
    </template>

    <template v-slot:cell(payment)="row">
      {{ row.value.map(method => paymentMethodLabels[method]).join(", ") }}
    </template>

    <template v-slot:cell(status)="row">
      <Label
        kind="inline"
        size="lg"
        weight="bold"
        v-bind:variant="statusVariants[row.item.status] ? `light-${statusVariants[row.item.status]}` : 'light'"
      >
        {{ statusLabels[row.item.status] }}
      </Label>

      <CarSuspension
        v-if="row.item.drivers.some(d => d.suspended)"
        v-bind:drivers="row.item.drivers"
      />
    </template>

    <template v-slot:cell(activity)="row">
      <CarActivity
        v-if="row.item.lastSeen"
        v-bind:last-seen="row.item.lastSeen"
      />
    </template>

    <template v-slot:cell(lastOrder)="row">
      <Order
        class="bg-light"
        v-if="row.value"
        v-bind:disabled="true"
        v-bind:order="row.value"
      />
    </template>

    <template v-slot:cell(actions)="row">
      <CarActions
        v-bind:car="row.item"
        v-on="$listeners"
      />
    </template>
  </b-table>
</template>

<script>
import Label from "@/view/content/widgets/Label.vue";
import Order from "@/view/content/widgets/Order.vue";
import TableMixin from "@/view/mixins/Table.js";

import CarActions    from "./CarActions.vue";
import CarActivity   from "./CarActivity.vue";
import CarSuspension from "./CarSuspension.vue";

export default {
  components: {
    CarActions,
    CarActivity,
    CarSuspension,

    Label,
    Order,
  },

  mixins: [
    TableMixin
  ],

  props: {
    cars: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      fields: [
        { key: "id",        label: "Indicativ",           class: "id",      sortable: true, sortDirection: "asc" },
        { key: "vehicle",   label: "Vehicul",             class: "vehicle", sortable: true, sortDirection: "asc" },
        { key: "payment",   label: "Modalitați de plată", class: "payment" },
        { key: "status",    label: "Stare",               class: "status" },
        { key: "activity",  label: "Ultima activitate",   class: "activity" },
        { key: "lastOrder", label: "Ultima comandă",      class: "order" },
        { key: "actions",   label: "Acțiuni",             class: "actions" }
      ],

      paymentMethodLabels: Object.freeze({
        "cash": "Numerar",
        "card": "Card (POS)",
      }),

      statusIcons: Object.freeze({
        disabled:  "fas fa-lock",
        suspended: "fas fa-minus-circle",

        away: "fas fa-taxi",
        free: "fas fa-taxi",
        busy: "fas fa-phone-slash",

        inOrder:   "fas fa-user-clock",
        inStation: "fas fa-taxi",
      }),

      statusLabels: Object.freeze({
        disabled:  "blocat",
        suspended: "suspendat",

        away: "inactiv",
        free: "liber",
        busy: "ocupat",

        inOrder:   "are comandă",
        inStation: "în stație",
      }),

      statusVariants: Object.freeze({
        disabled:  "danger",
        suspended: "warning",

        away: "",
        free: "success",
        busy: "primary",

        inOrder:   "primary",
        inStation: "info",
      }),
    };
  },

  methods: {
    revokePriority(car, priority) {
      this.$emit("revoke-priority", {
        car,
        priority,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table::v-deep {
  > thead, tfoot {
    > tr {
      > th {
        padding: 0.75rem 1rem;
        background: none !important;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &.id {
          width: 10rem;
        }

        &.vehicle {
          width: 10rem;
        }

        &.payment {
          width: 15rem;
        }

        &.status {
          width: 25rem;
        }

        &.activity {
          width: 15rem;
        }

        &.order {
          width: 35rem;
        }

        &.actions {
          width: 13rem;
        }

        &[aria-sort = ascending], &[aria-sort = descending] {
          color: $primary !important;

          i {
            color: $primary;
            font-size: 0.9rem;
            vertical-align: middle;
          }
        }
      }
    }
  }

  > tbody {
    > tr {
      > td {
        padding: 1rem;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        &.id > div {
          display: flex;
          align-items: center;
        }

        .symbol {
          margin-right: 1.5rem;

          .symbol-label {
            i {
              font-size: 24pt;
            }
          }
        }
      }
    }
  }
}
</style>
