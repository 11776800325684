<template>
  <div>
    <!-- disable -->
    <b-button
      class="btn-icon btn-light"
      size="sm"
      variant="hover-danger"
      v-if="isEnabled"
      v-b-tooltip.hover="'Dezactivează'"
      v-on:click="disable"
    >
      <i class="fas fa-ban text-danger" />
    </b-button>

    <!-- enable -->
    <b-button
      class="btn-icon btn-light"
      size="sm"
      variant="hover-success"
      v-else
      v-b-tooltip.hover="'Activează'"
      v-on:click="enable"
    >
      <i class="far fa-check-circle text-success" />
    </b-button>

    <!-- suspend -->
    <b-button
      class="btn-icon btn-light"
      size="sm"
      variant="hover-danger"
      v-b-tooltip.hover="'Blochează șoferi'"
      v-bind:disabled="allDriversSuspended"
      v-on:click="suspend"
    >
      <i
        class="fas fa-lock"
        v-bind:class="{ 'text-danger': !allDriversSuspended }"
      />
    </b-button>

    <!-- reinstate -->
    <b-button
      class="btn-icon btn-light"
      size="sm"
      variant="hover-success"
      v-b-tooltip.hover="'Deblochează șoferi'"
      v-bind:disabled="!anyDriverSuspended"
      v-on:click="reinstate"
    >
      <i
        class="fas fa-lock-open"
        v-bind:class="{ 'text-success': anyDriverSuspended }"
      />
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    car: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    isEnabled() {
      return !!this.car.enabled;
    },

    anyDriverSuspended() {
      return this.car.drivers.some(driver => driver.suspended);
    },

    allDriversSuspended() {
      return this.car.drivers.every(driver => driver.suspended);
    },
  },

  methods: {
    enable() {
      this.$emit("enable", this.car);
    },

    disable() {
      this.$emit("disable", this.car);
    },

    suspend() {
      this.$emit("suspend", this.car);
    },

    reinstate() {
      this.$emit("reinstate", this.car);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  margin-right: 0.5rem;

  &:last-child {
    margin: 0;
  }
}
</style>
