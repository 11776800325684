<template>
  <b-card class="card-custom card-stretch gutter-b" no-body>
    <b-card-header>
      <b-card-title class="font-weight-bolder">
        Indicative
      </b-card-title>

      <div class="card-toolbar">
        <div class="dropdown dropdown-inline">
          <div role="group" class="input-group input-group-sm">
            <b-input-group size="sm">
              <b-input-group-prepend v-bind:is-text="true">
                <i class="fas fa-search" />
              </b-input-group-prepend>

              <b-form-input class="form-control" size="sm" placeholder="indicativ, nr. auto, POS" v-model="search" />
            </b-input-group>
          </div>
        </div>
      </div>
    </b-card-header>

    <b-card-body>
      <Table
        v-bind:loading="loading"
        v-bind:cars="cars"
        v-bind:sort.sync="sort"
        v-on:enable="enable"
        v-on:disable="disable"
        v-on:suspend="suspend"
        v-on:reinstate="reinstate"
      />
    </b-card-body>

    <b-card-footer>
      <Pagination
        v-model="page"
        v-bind:page-size="pageSize"
        v-bind:page-options="pageOptions"
        v-bind:total="total"
        v-on:page-size-change="pageSizeChanged"
      />
    </b-card-footer>

    <EnableDialog ref="enableDialog" />
    <DisableDialog ref="disableDialog" />

    <SuspendDialog ref="suspendDialog" />
    <ReinstateDialog ref="reinstateDialog" />
  </b-card>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

import api from "@/core/services/api";
import notify from "@/core/services/notifications";
import { mapOrder } from "@/core/models/order";
import { formatPhoneNumber, formatAddress } from "@/core/utils";

import Pagination from "@/view/content/widgets/Pagination.vue";
import EnableDialog    from "@/view/content/dialogs/cars/Enable.vue";
import DisableDialog   from "@/view/content/dialogs/cars/Disable.vue";
import SuspendDialog   from "@/view/content/dialogs/cars/Suspend.vue";
import ReinstateDialog from "@/view/content/dialogs/cars/Reinstate.vue";
import ListMixin from "@/view/mixins/List.js";

import Table from "./list/Table";

function mapCar(car) {
  const created   = moment(car.created);
  const enabled   = car.enabled   ? moment(car.enabled)     : null;
  const lastSeen  = car.lastSeen  ? moment(car.lastSeen)    : null;
  const lastOrder = car.lastOrder ? mapOrder(car.lastOrder) : null;

  let status = car.status;
  if (!car.enabled) {
    status = "disabled";
  } else if (car.drivers.every(d => d.suspended)) {
    status = "suspended";
  }

  return {
    id: car.id,

    payment: car.payment,
    status,
    vehicle: car.vehicle,

    created: Object.freeze(created),
    enabled: Object.freeze(enabled),
    lastSeen: Object.freeze(lastSeen),

    drivers: car.drivers,
    lastOrder,
  };
}

export default {
  name: "Cars",

  components: {
    Table,
    Pagination,

    EnableDialog,
    DisableDialog,
    SuspendDialog,
    ReinstateDialog,
  },

  filters: {
    address: formatAddress,
    phone:   formatPhoneNumber,
  },

  mixins: [
    ListMixin
  ],

  data() {
    return {
      cars: [],

      debounceDelay: moment.duration(250, "ms"),
      defaultSort: "id",
    };
  },

  mounted() {
    this.$store.dispatch("setBreadcrumb", [{ title: "Indicative" }]);
  },

  methods: {
    ...mapActions(["overridePageLayoutConfig"]),

    async load() {
      this.loading = true;

      const response = await api.cars.list(this.offset, this.limit, this.search, this.sort);

      this.loading = false;
      this.total   = response.range.total;
      this.cars    = response.results.map(mapCar);
    },

    async refresh(car) {
      const response = await api.cars.load(car.id);

      this.replace(response);
    },

    replace(car) {
      const mapped = mapCar(car);

      const index = this.cars.findIndex(c => c.id === car.id);
      if (index !== -1) {
        this.$set(this.cars, index, mapped);
      }
    },

    async enable(car) {
      const enabled = await this.$refs.enableDialog.show(car);

      if (enabled) {
        const updated = await api.cars.enable(car.id);

        this.replace(updated);

        notify.info(`Indicativul <b>${car.id}</b> a fost activat.`);
      }
    },

    async disable(car) {
      const disabled = await this.$refs.disableDialog.show(car);

      if (disabled) {
        const updated = await api.cars.disable(car.id);

        this.replace(updated);

        notify.info(`Indicativul <b>${car.id}</b> a fost dezactivat.`);
      }
    },

    async suspend(car) {
      const suspended = await this.$refs.suspendDialog.show(car);

      if (suspended) {
        const { drivers, duration, reason } = suspended;

        for (const driver of drivers) {
          await api.drivers.suspend(driver.id, duration.toISOString(), reason);

          notify.info(`Șoferul <b>${driver.name}</b> a fost blocat pentru <b>${duration.humanize()}</b>.`);
        }

        await this.refresh(car);
      }
    },

    async reinstate(car) {
      const reinstated = await this.$refs.reinstateDialog.show(car);

      if (reinstated) {
        for (const driver of reinstated) {
          await api.drivers.reinstate(driver.id);

          driver.suspended = null;

          notify.info(`Șoferul <b>${driver.name}</b> a fost deblocat.`);
        }

        await this.refresh(car);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  .card-header {
    border-bottom: none;
  }

  .card-body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .card-footer {
    .pagination {
      margin-bottom: 0;
    }

    .dropdown {
      margin-bottom: 0;
    }
  }
}
</style>
