<template>
  <div
    class="font-weight-bolder font-size-lg d-inline"
    v-b-tooltip
    v-bind:title="lastSeen | dateTime"
  >
    <span class="text-success" v-if="lastSeenAgo.asMinutes() >= -1">{{ lastSeen | ago }}</span>
    <span class="text-muted" v-else-if="lastSeenAgo.asDays() >= -1">{{ lastSeen | ago }}</span>
    <span class="text-warning" v-else-if="lastSeenAgo.asWeeks() >= -3">{{ lastSeen | ago }}</span>
    <span class="text-danger" v-else>{{ lastSeen | ago }}</span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  filters: {
    ago: timestamp => moment(timestamp).fromNow(),
    dateTime: timestamp => moment(timestamp).format('D MMMM Y [la] HH:mm:ss'),
  },

  props: {
    lastSeen: {
      type: moment,
      required: true,
    },
  },

  data() {
    return {

    };
  },

  computed: {
    lastSeenAgo() {
      return moment.duration(this.lastSeen.diff(moment()));
    },
  },
};
</script>
