<template>
  <div
    class="order d-flex align-items-center rounded"
    v-bind:class="[backgroundClass, order.status, { disabled: disabled }]"
  >
    <span
      v-if="showMultiple"
      class="order-multiple ml-0 mr-4"
      v-bind:class="`text-${color}`"
    >
      {{ order.multiple.index }} / {{ order.multiple.count }}
    </span>

    <div class="order-details d-flex flex-column flex-grow-1">
      <div v-if="formattedTimestamp" class="order-timestamp">
        {{ formattedTimestamp }}
      </div>

      <div
        class="order-address"
        v-ellipsis-tooltip
        v-b-tooltip.hover="{ customClass: 'order-address-tooltip' }"
        v-bind:title="order.address | address"
        v-on:click="resendOrder"
      >
        {{ order.address | address }}
      </div>

      <div class="d-flex flex-row">
        <div
          class="order-customer"
          v-b-tooltip.hover="'Click pentru a răspunde la apel.'"
          v-bind:disabled="disabled"
          v-on:click="customerClicked"
        >
          {{ order.customer.name }} | {{ order.customer.phone | phone }}
        </div>
        <div class="order-notes">{{ notes }}</div>
      </div>

      <div class="d-flex flex-row">
        <div v-if="showConfirmed" class="mr-5">
          <div class="order-car">
            <span class="order-car-id">
              <i class="fas fa-taxi" />

              {{ order.car.id }}
            </span>

            <span v-if="order.car.driver" class="order-driver">| {{ order.car.driver.name }}</span>
            <span class="order-duration">| {{ order.duration | duration }}</span>
          </div>
        </div>

        <div v-if="showCancelled" class="mr-5">
          <div class="order-reason" v-bind:class="[reasonColorClass]">
            <i class="fas fa-exclamation-circle" />

            {{ cancellationReason }}
          </div>
        </div>

        <div v-if="order.dispatch && order.dispatch.name">
          <div class="order-dispatch">
            <i class="fas fa-id-badge" />

            {{ order.dispatch.name }}
          </div>
        </div>
      </div>
    </div>

    <a
      v-if="showCancelButton"
      v-on:click.stop="cancelOrder"
      class="order-cancel btn btn-link-danger"
    >
      <i class="fas fa-times-circle" />
    </a>

    <a
      v-if="showRemoveButton"
      v-on:click.stop="removeOrder"
      class="order-remove btn btn-link-danger"
    >
      <i class="fas fa-times-circle" />
    </a>

    <span v-if="showRemovingSpinner" class="spinner-border text-danger" />
  </div>
</template>

<script>
import moment from "moment";
import { isNullOrEmpty, formatPhoneNumber, formatAddress } from "@/core/utils";

const statusColors = {
  sent: "warning",

  confirmed: "success",
  cancelled: "danger",
};

const cancellationReasons = {
  addressIncomplete: "adresă insuficientă",

  carNotFound: "nicio mașină disponibilă",

  cancelledByCustomer: "anulată de către client",
  cancelledByDispatch: "anulată de către dispecer",

  customerSuspended:    "clientul a fost blocat",
  customerDidNotArrive: "clientul nu a coborât la mașină",

  driverDidNotComplete: "șoferul nu a finalizat comanda",
};

export default {
  directives: {
    ellipsisTooltip: {
      inserted: element => {
        const tolerance  = 2; // px
        const ellipsized = element.offsetWidth + tolerance < element.scrollWidth;

        if (!ellipsized) {
          element.removeAttribute("title");
          element.setAttribute("disabled", "");
        }
      },
    },
  },

  filters: {
    address:  formatAddress,
    phone:    formatPhoneNumber,

    duration: duration  => moment.duration(duration).humanize(),
  },

  props: {
    order: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    timestamp: {
      type: String,
      default: "created",
    },

    timestampFormat: {
      type: String,
      default: "dddd, D MMMM Y [ora] HH:mm:ss",
    },
  },

  data() {
    return {
      color: statusColors[this.order.status],

      cancellationReason: cancellationReasons[this.order.reason],
    };
  },

  computed: {
    backgroundClass() {
      if (this.disabled) {
        return null;
      } else if (this.order.activeCall) {
        return "bg-light-info";
      } else if (this.order.status === "cancelled" && this.order.reason === "cancelledByDispatch") {
        return "bg-light-secondary";
      } else {
        const color = statusColors[this.order.status];

        return `bg-light-${color}`;
      }
    },

    reasonColorClass() {
      if (this.order.status === "cancelled" && this.order.reason === "cancelledByDispatch") {
        return "text-secondary";
      } else {
        return "text-danger";
      }
    },

    notes() {
      const notes    = this.order.notes;
      const landmark = this.order.address.landmark;

      const hasNotes    = !isNullOrEmpty(notes);
      const hasLandmark = !isNullOrEmpty(landmark);

      if (hasLandmark && hasNotes) {
        return `${landmark}; ${notes}`;
      } else if (hasLandmark) {
        return landmark;
      } else if (hasNotes) {
        return notes;
      } else {
        return null;
      }
    },

    formattedTimestamp() {
      if (this.timestamp && this.timestampFormat && this.order[this.timestamp]) {
        return moment(this.order[this.timestamp]).format(this.timestampFormat);
      } else {
        return null;
      }
    },

    isSent() {
      return this.order.status === "sent";
    },

    isCancelled() {
      return this.order.status === "cancelled";
    },

    isCancelling() {
      return this.order.cancelling;
    },

    isConfirmed() {
      return this.order.status === "confirmed" || this.order.status === "arrived" || this.order.status === "completed";
    },

    isMultiple() {
      return !!this.order.multiple;
    },

    isRemoving() {
      return this.order.removing;
    },

    showCancelButton() {
      return !this.disabled && !this.isCancelling && this.isSent;
    },

    showCancelled() {
      return this.isCancelled;
    },

    showConfirmed() {
      return this.isConfirmed;
    },

    showMultiple() {
      return this.isMultiple;
    },

    showRemoveButton() {
      return !this.disabled && !this.isRemoving && !this.isCancelling && (this.isConfirmed || this.isCancelled);
    },

    showRemovingSpinner() {
      return !this.disabled && (this.isCancelling || this.isRemoving);
    },
  },

  methods: {
    customerClicked() {
      if (this.disabled) return;

      this.$emit("customer-click", this.order.customer.phone);
    },

    cancelOrder() {
      if (this.disabled) return;

      this.$emit("cancel-order", this.order);
    },

    removeOrder() {
      if (this.disabled) return;

      this.$emit("remove-order", this.order);
    },

    resendOrder() {
      if (this.disabled) return;

      this.$emit("resend-order", this.order);
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  white-space: nowrap;

  &:last-child {
    margin-bottom: 0;
  }

  &.cancelled:not(.disabled) {
    .order-address {
      cursor: pointer;
    }
  }

  .order-details {
    min-width: 0;

    .order-timestamp {
      color: $dark-75;
      font-size: $font-size-sm;
      margin-bottom: 0.25rem;
    }

    .order-address {
      color: $dark-75;
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      margin-bottom: 0.25rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .order-notes {
      color: $info;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .order-customer {
      color: $primary;
      margin-right: 1.25rem;
      margin-bottom: 0.25rem;

      &:hover:not([disabled]) {
        cursor: pointer;
        color: $success;
      }
    }

    .order-car {
      font-weight: $font-weight-bold;

      .order-car-id {
        color: $info;

        i {
          color: $info;
        }
      }

      .order-driver {
        color: $text-muted;
        margin-right: 0.25rem;
      }

      .order-duration {
        color: $info;
      }
    }

    .order-reason {
      i {
        position: relative;
        top: 0.125rem;
      }
    }
  }

  .order-dispatch {
    color: $primary;

    i {
      color: $primary;
      position: relative;
      top: 0.075rem;
    }
  }

  .order-multiple {
    font-size: $font-size-lg;
    font-weight: $font-weight-bolder;
    margin-left: 0.5rem;
  }

  .order-cancel, .order-remove {
    margin-left: 1rem;
  }
}
</style>

<style lang="scss">
.order-address-tooltip {
  max-width: 20%;

  .tooltip-inner {
    max-width: 100%;
  }
}
</style>
