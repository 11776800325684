<template>
  <b-modal
    centered
    ok-title="Activează"
    cancel-title="Renunță"
    cancel-variant="secondary"
    v-model="visible"
    v-bind:title="title"
    v-on:hide="resolve($event.trigger === 'ok')"
  >
    <p v-if="car" class="font-size-h6">
      Sunteți sigur că doriți să activați indicativul <strong>{{ car.id }}</strong>?
    </p>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      car: null,

      resolve: null,
      reject:  null,

      visible: false,
    };
  },

  computed: {
    title() {
      if (this.car) {
          return `Activare indicativ ${this.car.id}`;
      } else {
          return "";
      }
    },
  },

  destroyed() {
    if (this.reject) {
      this.reject(new Error("Dialog destroyed before resolve."));
    }
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    show(car, priority) {
      this.car = car;

      this.visible = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject  = reject;
      });
    },

    hide() {
      this.car = null;

      this.resolve = null;
      this.reject  = null;

      this.visible = false;
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .modal {
    .modal-dialog {
      .modal-body {
        p {
          margin-bottom: 0;
        }
      }
      .modal-footer {
        justify-content: space-between;
      }
    }
  }
}
</style>
